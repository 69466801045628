<template>
    <div v-if="hasToken">
        <nav class="small-navbar vh-100 d-flex align-items-center">
            <div class="side-menu">
                <div class="icons" :class="{ active: $route.path == '/' }">
                    <i class="icomoon icon-home"></i>
                </div>
                <div
                    v-if="currUserRole != 'account_manager'"
                    class="icons"
                    :class="{ active: $route.path.includes('search') }"
                >
                    <i class="icomoon icon-search"></i>
                </div>
                <div class="icons" :class="{ active: $route.path.includes('lead') }">
                    <i class="icomoon icon-students"></i>
                </div>
                <div class="icons" :class="{ active: $route.path.includes('applications') }">
                    <i class="icomoon icon-applications"></i>
                </div>
                <template v-if="isAdmin && !isActAsMember">
                    <div class="icons" :class="{ active: $route.path.includes('commission') }">
                        <i class="icomoon icon-commission"></i>
                    </div>
                    <div class="icons" :class="{ active: $route.path.includes('team-members') }">
                        <i class="icomoon icon-team-members"></i>
                    </div>
                </template>
            </div>
        </nav>

        <nav class="stretch-navbar vh-100 d-flex align-items-center">
            <div class="side-menu">
                <nuxt-link class="icons" to="/">
                    <i class="icomoon icon-home"></i>
                    <p>Home</p>
                </nuxt-link>
                <nuxt-link
                    v-if="currUserRole != 'account_manager'"
                    class="icons"
                    to="/university"
                    @click="resetFilters"
                >
                    <i class="icomoon icon-search"></i>
                    <div class="search-categories">
                        <p>Search</p>
                        <p class="search-content">English schools, Universities, Courses</p>
                    </div>
                </nuxt-link>
                <nuxt-link
                    class="icons"
                    to="/leads"
                    :class="{ 'router-link-exact-active': $route.path.includes('lead') }"
                >
                    <i class="icomoon icon-students"></i>
                    <p>Students</p>
                </nuxt-link>
                <nuxt-link class="icons" to="/applications">
                    <i class="icomoon icon-applications"></i>
                    <p>Applications</p>
                </nuxt-link>
                <template v-if="isAdmin && !isActAsMember">
                    <nuxt-link class="icons" to="/commission">
                        <i class="icomoon icon-commission"></i>
                        <p>Commission</p>
                    </nuxt-link>
                    <nuxt-link
                        class="icons"
                        to="/team-members"
                        :class="{ 'router-link-exact-active': $route.path.includes('members') }"
                    >
                        <i class="icomoon icon-team-members"></i>
                        <p>Team Members</p>
                    </nuxt-link>
                </template>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { useCommonStore } from '../../../libs/shared/store/search/common';
const commonStore = useCommonStore();
const { preventClear } = storeToRefs(commonStore);

const resetFilters = () => {
    preventClear.value = false;
};
</script>

<script>
export default {
    mounted() {
        this.hasToken = useCookie('token');
        const userRole = useCookie('subagent_userInfo').value?.role;
        this.currUserRole = userRole;
        this.isAdmin = userRole === 'admin' || userRole === 'account_manager' ? true : false;

        const isActAsMember = useCookie('isActAsMember');

        if (isActAsMember.value) {
            // only if usert is act as member , set the member data
            const actAsMemberData = useCookie('actAsMemberData');
            this.$store.dispatch('functions/setActAsMember', isActAsMember.value);
            this.$store.dispatch('functions/setActAsMemberData', actAsMemberData.value);
        }
    },
    data() {
        return {
            currUserRole: null,
            isAdmin: false,
            hasToken: false,
        };
    },
    computed: {
        isActAsMember() {
            return this.$store.state.functions.isActAsMember;
        },
    },
};
</script>

<style lang="scss" scoped>
.stretch-navbar {
    cursor: pointer;
    background-color: $primary-color;
    width: toRem(350);
    box-shadow: toRem(4) toRem(4) toRem(8) 0 $dark4;
    @include position($position: fixed, $top: 0, $left: 0, $z-index: 101);
    left: -100%;
    transition: 0.5s all ease-in-out;

    .side-menu {
        .icons {
            display: flex;
            align-items: center;
            width: toRem(330);
            border-radius: 0 toRem(10) toRem(10) 0;
            padding: 1rem 0 1rem toRem(40);
            margin: toRem(36) 0;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
                background-color: $secondary-color;
                color: $primary-color;

                i {
                    color: $primary-color;
                }

                p {
                    color: $primary-color;
                }
            }

            i {
                padding-inline-end: toRem(16);
                color: $white;
                font-size: toRem(28);
            }

            p {
                font-size: toRem(18);
                color: $white;
            }

            .search-content {
                font-size: toRem(12);
            }
        }

        .router-link-exact-active {
            background-color: $secondary-color;

            i {
                color: $primary-color;
            }

            p {
                color: $primary-color;
            }
        }
    }

    &:hover {
        left: 0;
    }
}

.small-navbar {
    cursor: pointer;
    background-color: $white;
    width: toRem(93);
    box-shadow: toRem(4) toRem(4) toRem(8) 0 $dark4;
    @include position($position: fixed, $top: 0, $left: 0, $z-index: 100);

    .side-menu {
        background-color: $primary-color;
        width: toRem(65);
        padding: 0;
        @include position($position: relative, $left: 0);
        transition: 0.5s all;

        .icons {
            display: flex;
            justify-content: center;
            margin: toRem(35) 0;
            cursor: pointer;
            position: relative;

            i {
                color: $white;
                font-size: toRem(28);
            }
        }

        .active {
            &::after {
                content: '';
                @include position($position: absolute, $left: toRem(93));
                background-color: $primary-color;
                height: toRem(40);
                width: toRem(2);
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
            @include position($position: absolute, $top: -104px, $left: 0, $z-index: -1);
            height: 300px;
            width: 100%;
            background-image: url('/assets/img/sidebar/sidebar_edge.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::after {
            top: auto;
            bottom: -104px;
            transform: scaleY(-1);
        }
    }

    &:hover {
        box-shadow: none;

        .side-menu {
            left: -100%;
            opacity: 0;

            .active::after {
                transition: 0s;
                opacity: 0;
            }
        }

        ~ .stretch-navbar {
            left: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .stretch-navbar {
        width: toRem(300);

        .side-menu {
            .icons {
                width: toRem(280);
                padding: 1rem 0 1rem toRem(30);
                margin: toRem(30) 0;

                i {
                    padding-inline-end: toRem(14);
                    font-size: toRem(24);
                }

                p {
                    font-size: toRem(14);
                }

                .search-content {
                    font-size: toRem(9);
                }
            }
        }
    }

    .small-navbar {
        width: toRem(67);

        .side-menu {
            width: toRem(55);

            .icons {
                margin: toRem(35) 0;

                i {
                    font-size: toRem(24);
                }
            }

            .active {
                &::after {
                    @include position($position: absolute, $left: toRem(67));
                    height: toRem(32);
                    width: toRem(2);
                }
            }
        }
    }
}

@media screen and (min-width: 1900px) {
    .stretch-navbar {
        width: toRem(350);

        .side-menu {
            .icons {
                width: toRem(330);
                padding: 1rem 0 1rem toRem(40);
                margin: toRem(36) 0;

                i {
                    padding-inline-end: toRem(16);
                    font-size: toRem(28);
                }

                p {
                    font-size: toRem(18);
                }

                .search-content {
                    font-size: toRem(12);
                }
            }
        }
    }

    .small-navbar {
        width: toRem(93);

        .side-menu {
            // width: toRem(74);

            .icons {
                margin: toRem(40) 0;

                i {
                    font-size: toRem(28);
                }
            }

            .active {
                &::after {
                    @include position($position: absolute, $left: toRem(93));
                    height: toRem(40);
                    width: toRem(2);
                }
            }
        }
    }
}
</style>
